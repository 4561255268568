import {
  HeroPictureDocument,
  HeroPictureQuery,
} from "app/home/Hero/hero-picture.query.generated";
import { initializeApollo } from "utils/with-apollo.utils";

export const fetchHero = async () => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<HeroPictureQuery>({
    query: HeroPictureDocument,
  });

  const dataAttributes = data.websiteSetting?.data?.attributes;
  const heroData = dataAttributes?.isBlackFriday
    && !! dataAttributes?.heroPictureBlackFriday?.data
    ? dataAttributes?.heroPictureBlackFriday?.data
    : dataAttributes?.heroPicture?.data;

  return {
    alternativeText: heroData?.attributes?.alternativeText ?? null,
    medium: heroData?.attributes?.formats?.medium?.url ?? null,
    large: heroData?.attributes?.formats?.large?.url ?? null,
    thumbnail: heroData?.attributes?.formats?.thumbnail?.url ?? null,
    name: heroData?.attributes?.name ?? "",
  }
};
