import * as Types from '../../../graphql.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HeroPictureQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HeroPictureQuery = { __typename?: 'query_root', websiteSetting?: Types.Maybe<{ __typename?: 'WebsiteSettingEntityResponse', data?: Types.Maybe<{ __typename?: 'WebsiteSettingEntity', id?: Types.Maybe<string>, attributes?: Types.Maybe<{ __typename?: 'WebsiteSetting', isBlackFriday: boolean, heroPictureBlackFriday?: Types.Maybe<{ __typename?: 'UploadFileEntityResponse', data?: Types.Maybe<{ __typename?: 'UploadFileEntity', id?: Types.Maybe<string>, attributes?: Types.Maybe<{ __typename?: 'UploadFile', alternativeText?: Types.Maybe<string>, formats?: Types.Maybe<any>, name: string, url: string }> }> }>, heroPicture: { __typename?: 'UploadFileEntityResponse', data?: Types.Maybe<{ __typename?: 'UploadFileEntity', id?: Types.Maybe<string>, attributes?: Types.Maybe<{ __typename?: 'UploadFile', alternativeText?: Types.Maybe<string>, formats?: Types.Maybe<any>, name: string, url: string }> }> } }> }> }> };


export const HeroPictureDocument = gql`
    query HeroPicture {
  websiteSetting {
    data {
      id
      attributes {
        isBlackFriday
        heroPictureBlackFriday {
          data {
            id
            attributes {
              alternativeText
              formats
              name
              url
            }
          }
        }
        heroPicture {
          data {
            id
            attributes {
              alternativeText
              formats
              name
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useHeroPictureQuery(baseOptions?: Apollo.QueryHookOptions<HeroPictureQuery, HeroPictureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeroPictureQuery, HeroPictureQueryVariables>(HeroPictureDocument, options);
      }
export function useHeroPictureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeroPictureQuery, HeroPictureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeroPictureQuery, HeroPictureQueryVariables>(HeroPictureDocument, options);
        }
export type HeroPictureQueryHookResult = ReturnType<typeof useHeroPictureQuery>;
export type HeroPictureLazyQueryHookResult = ReturnType<typeof useHeroPictureLazyQuery>;
export type HeroPictureQueryResult = Apollo.QueryResult<HeroPictureQuery, HeroPictureQueryVariables>;